
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
import { DispatchDto } from '@/api/dispatch'
export default defineComponent({
    name: 'InsuranceAddSubtractAdd',
    components: {
        'alert-box': alertBox
    },
    props: ['dispatch'],
    data () {
        return {
            info: {
                company_name: '',
                company_id: null as null | number,
                occupation_name: '',
                occupation_id: null as null | number
            },
            occupationAutoData: [] as {
                occupation_id: number
                name: string
                level: number
            }[]
        }
    },
    methods: {
        // 被派遣单位自动完成
        async patchAuto (
            queryString: string,
            cb: (
                val: {
                    value: string
                    id: number
                    occupation: {
                        occupation_id: number
                        name: string
                        level: number
                    }[]
                }[]
            ) => void
        ): Promise<void> {
            const result = this.dispatch
            cb(
                result
                    .map((item: DispatchDto) => {
                        return {
                            value: item.name,
                            id: item.company_id,
                            occupation: item.occupation
                        }
                    })
                    .filter((item: { value: string }) =>
                        item.value.includes(queryString)
                    )
            )
        },
        // 被派遣单位选择
        selectPatch (item: {
            value: string
            id: number
            occupation: { occupation_id: number; name: string; level: number }[]
        }) {
            this.info.company_id = item.id
            this.occupationAutoData = item.occupation
            this.autoFillOccupation()
        },
        autoFillOccupation () {
            let theOne = this.occupationAutoData[0]
            const the4 = this.occupationAutoData.find(function (item) {
                return item.level === 4
            })
            if (the4) theOne = the4
            this.info.occupation_name = theOne.name + `（${theOne.level}类）`
            this.info.occupation_id = theOne.occupation_id
        },
        // 工种选择自动完成
        async occupationAuto (
            queryString: string,
            cb: (
                val: {
                    value: string
                    id: number
                }[]
            ) => void
        ): Promise<void> {
            console.log(this.occupationAutoData)
            cb(
                this.occupationAutoData
                    .filter(item => item.name.indexOf(queryString) !== -1)
                    .map(item => {
                        return {
                            value: item.name + `（${item.level}类）`,
                            id: item.occupation_id
                        }
                    })
            )
        },
        // 工种选择
        selectOccupation (value: number) {
            const obj = this.occupationAutoData.find(function (item) {
                return item.occupation_id === value
            })
            this.info.occupation_id = value
            this.info.occupation_name = obj?.name + '（' + obj?.level + '类）'
        },
        // 提交
        workerAdd () {
            if (!this.info.company_id) {
                this.$message.error('请选择被派遣单位')
                return
            }
            if (!this.info.occupation_id) {
                this.$message.error('请选择工种')
                return
            }
            this.$emit('complete', this.info)
        },
        // 取消
        cancel () {
            this.$emit('cancel')
        }
    }
})
