
// TODO 多选在分页时存在问题
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
import { getWorkers } from '@/api/worker'
import { WorkerDto } from '@/api/dto'
export default defineComponent({
    props: ['isRadio', 'policyId'],
    components: {
        'alert-box': alertBox
    },
    data () {
        return {
            searchText: '',
            pageNum: 1,
            pageSize: 5,
            total: 0,
            tableData: [] as WorkerDto[],
            selection: [] as WorkerDto[]
        }
    },
    created () {
        this.getReportEjectList()
    },
    methods: {
        // 选择出险人
        getReportEjectList () {
            getWorkers({
                search: this.searchText,
                policyId: this.policyId,
                page: this.pageNum,
                pageSize: this.pageSize,
                onlyActive: ''
            }).then(res => {
                this.total = res.total
                this.tableData = res.data
            })
        },
        handleSizeChange () {
            this.pageNum = 1
            this.getReportEjectList()
        },
        close () {
            this.selection = []
            this.searchText = ''
            this.pageNum = 1
            this.$emit('close')
        },
        selectAll () {
            if (this.isRadio) {
                (this.$refs as any).multipleTable.clearSelection()
                this.$message.warning('仅可选择一项')
            }
        },
        select (selection: WorkerDto[], row: any) {
            console.log(selection)
            if (this.isRadio && selection.length) {
                setTimeout(() => {
                    const arr = [selection[selection.length - 1]]
                    this.selection = arr
                }, 100)
                ;(this.$refs as any).multipleTable.clearSelection()
                ;(this.$refs as any).multipleTable.toggleRowSelection(row)
            }
        },
        handleSelectionChange (selection: WorkerDto[]) {
            this.selection = selection
        },
        confirm () {
            this.$emit('confirm', this.selection)
        }
    }
})
