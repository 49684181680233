
import { defineComponent } from 'vue'
import pageHead from '../components/page-head.vue'
import selectPersonnel from '@/views/index/components/select-personnel.vue'
import addWorker from './components/add-worker.vue'
import setWorker from './components/set-worker.vue'
import resultConfirm from './components/result-confirm.vue'
import { WorkerDto } from '@/api/dto'
import {
    InsuranceAddWorkerDto,
    InsuranceSubtract,
    InsuranceAdd,
    getInsuranceDetail,
    InsuranceAddMonth
} from '@/api/insurance'
import { getDispatch, DispatchDto } from '@/api/dispatch'
import $store from '@/store'

import xlsx from 'xlsx'

export default defineComponent({
    name: 'InsuranceAddSubtract',
    components: {
        'page-head': pageHead,
        'select-personnel': selectPersonnel,
        'add-worker': addWorker,
        'set-worker': setWorker,
        'result-confirm': resultConfirm
    },
    data () {
        return {
            name: '',
            id: '' as number | string,
            pageNum: 1,
            pageSize: 5,
            tableData: [] as InsuranceAddWorkerDto[],
            selects: [] as InsuranceAddWorkerDto[],
            addWorkerInit: '' as InsuranceAddWorkerDto | '',
            total: 0,
            isSubmitting: false,
            startDate: '',
            type: '',
            needStartDate: true,
            isWorkerSelect: false,
            isAddWorker: false,
            isSetWorker: false,
            isConfirm: undefined as undefined | (() => void),
            dispatchList: [] as DispatchDto[],
            dispatchOccupation: [] as DispatchDto[],
            planType: '',
            payMoney: 0,
            payCount: 0,
            resultKey: 0,
            $store
        }
    },
    async created () {
        this.id = this.$route.params.id as unknown as number
        this.type = this.$route.params.type as string
        console.log(this.$route.params.type)
        console.log(this.$route.params, 'this.$route.params')
        const result = await getInsuranceDetail({
            policyId: this.id
        })
        this.name = result.plan.name
        this.planType = result.plan.type
        if (result.plan.type === '日日保') {
            this.needStartDate = false
        }
        const result2 = await getDispatch({
            policyId: this.id,
            page: 0,
            pageSize: 200
        })
        this.dispatchList = JSON.parse(JSON.stringify(result2.data))
        // 清洗被派遣单位列表，让最高的工种在第一位
        this.dispatchOccupation = result2.data.map(item => {
            for (let i = 0; i < item.occupation.length; i++) {
                if (item.occupation[i].level > item.occupation[0].level) {
                    const temp = item.occupation[0]
                    item.occupation[0] = item.occupation[i]
                    item.occupation[i] = temp
                }
            }
            return item
        })
        this.dispatchOccupation = JSON.parse(JSON.stringify(result2.data))
    },
    methods: {
    // 下载模板
        downloadTemplete () {
            window.location.href = 'https://oss.openinsu.com/public/files/%E5%8A%A0%E5%87%8F%E4%BF%9D%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xls'
            this.$message.success('模板下载成功！')
        },
        // 上传文件
        onImport (file: { raw: File }) {
            // 读取文件流
            var reader = new FileReader()
            reader.onload = e => {
                try {
                    const data = (e.target as any).result
                    const workbook = xlsx.read(data, { type: 'binary' })
                    // 获取第一张数据表
                    const sheetName = workbook.SheetNames[0]
                    // 主数据表
                    const sheet = workbook.Sheets[sheetName]
                    // 获取数据
                    const result = xlsx.utils.sheet_to_json(sheet) as { [key: string]: string }[]
                    // 得到第一列和第二列的键值
                    const keys = Object.keys(result[0])
                    this.filterPush(
                        result.map(item => {
                            if (item[keys[2]]) {
                                let company_name = ''
                                let company_id
                                let occupation_name = ''
                                let occupation_id
                                for (const dispatch of this.dispatchOccupation) {
                                    if (dispatch.name === item[keys[2]].trim()) {
                                        company_name = dispatch.name
                                        company_id = dispatch.company_id
                                        occupation_name = dispatch.occupation[0].name + `（${dispatch.occupation[0].level}类）`
                                        occupation_id = dispatch.occupation[0].occupation_id
                                        if (item[keys[3]]) {
                                            let occupationFlag = false
                                            for (const occupation of dispatch.occupation) {
                                                if (occupation.name === String(item[keys[3]]).trim()) {
                                                    occupation_name = occupation.name + `（${occupation.level}类）`
                                                    occupation_id = occupation.occupation_id
                                                    occupationFlag = true
                                                }
                                            }
                                            if (occupationFlag === false) {
                                                occupation_name = ''
                                                occupation_id = undefined
                                            }
                                        }
                                    }
                                }
                                return {
                                    name: item[keys[0]],
                                    idcard: item[keys[1]],
                                    company_name,
                                    company_id,
                                    occupation_name,
                                    occupation_id
                                }
                            } else {
                                return {
                                    name: item[keys[0]],
                                    idcard: item[keys[1]]
                                }
                            }
                        })
                    )
                } catch (e) {
                    this.$message.error(
                        'EXCEL内容读取失败，请检查EXCEL格式内容是否正确'
                    )
                }
            }
            reader.readAsBinaryString(file.raw)
        },
        // 过滤重复数据并添加
        filterPush (data: InsuranceAddWorkerDto[]) {
            console.log(data)
            const count = data.length
            let repeatNum = 0
            // 与已有数据对比是否重复
            this.tableData.forEach(item => {
                data = data.filter(item2 => {
                    if (item.idcard === item2.idcard) {
                        repeatNum++
                    }
                    return item.idcard !== item2.idcard
                })
            })
            // // 与本身对比是否重复
            // data.forEach(item => {
            //     data = data.filter(item2 => {
            //         repeatNum++
            //         return item.idcard !== item2.idcard
            //     })
            // })
            this.tableData.push(...data)
            if (count === repeatNum) {
                this.$message.error(
                    '导入失败，' + repeatNum + '条员工记录已存在！'
                )
            } else if (repeatNum === 0) {
                this.$message.success(count + '条员工记录导入成功！')
            } else {
                this.$message.warning(
                    `本次共导入${count}条员工记录，其中${repeatNum}条员工记录由于重复记录导致失败！`
                )
            }
        },
        // 在线员工选择
        workerSelect (select: WorkerDto[]) {
            console.log(select)
            this.filterPush(
                select.map(item => {
                    return {
                        name: item.name,
                        idcard: item.idcard
                    }
                })
            )
            this.isWorkerSelect = false
        },
        // 手动添加员工
        workerAdd (data: InsuranceAddWorkerDto) {
            if (this.addWorkerInit) {
                this.tableData.splice(
                    this.tableData.indexOf(this.addWorkerInit),
                    1,
                    data
                )
            } else {
                this.filterPush([data])
            }
            this.cancelWorkerAdd()
        },
        // 取消员工手动添加
        cancelWorkerAdd () {
            this.isAddWorker = false
            this.addWorkerInit = ''
        },
        // 员工选择
        handleSelectionChange (data: InsuranceAddWorkerDto[]) {
            this.selects = data
        },
        // 删除选择的员工
        deleteSelect () {
            this.selects.forEach(item => {
                this.tableData.splice(this.tableData.indexOf(item), 1)
                // console.log(this.tableData.indexOf(item))
            })
        },
        // 修改单条员工记录
        editWorker (data: InsuranceAddWorkerDto) {
            this.addWorkerInit = data
            this.isAddWorker = true
        },
        // 删除单条员工记录
        deleteWorker (data: InsuranceAddWorkerDto) {
            this.tableData.splice(this.tableData.indexOf(data), 1)
        },
        // 批量设置员工工种
        workerSet (data: {
      name: string
      idcard: string
      company_name: string
      company_id: number
      occupation_name: string
      occupation_id: number
    }) {
            this.selects.forEach(item => {
                data.name = item.name
                data.idcard = item.idcard
                this.tableData.splice(
                    this.tableData.indexOf(item),
                    1,
                    JSON.parse(JSON.stringify(data))
                )
            })
            this.isSetWorker = false
        },
        // 提交内容校验
        submitCheck () {
            if (!this.startDate && this.needStartDate) {
                this.$message.error('请选择生效日期')
                throw new Error()
            }
            if (this.tableData.length === 0) {
                this.$message.error('请添加人员')
                throw new Error()
            }
        },
        // 提交加保
        async onSubmitAdd () {
            this.submitCheck()
            // 加保需要设置被派遣单位
            for (let i = 0; i < this.tableData.length; i++) {
                if (!this.tableData[i].company_id) {
                    this.$message.error(
                        `第${i + 1}行未设置被派遣单位，无法提交`
                    )
                    return
                }
                if (!this.tableData[i].occupation_id) {
                    this.$message.error(`第${i + 1}行未设置工种，无法提交`)
                    return
                }
            }
            const data = {
                policyId: this.$route.params.id as unknown as number,
                startDate: this.startDate,
                persons: this.tableData
            }
            if (this.planType === '月保单') {
                const result = await InsuranceAddMonth(data)
                this.payMoney = result.pay_money
                this.payCount = result.pay_count
            }
            this.isConfirm = async () => {
                if (this.isSubmitting) {
                    this.resultKey++
                    return
                }
                this.isSubmitting = true
                try {
                    await InsuranceAdd(data)
                    this.$message.success('提交加保成功，正在为您返回上一页')
                    this.$router.go(-1)
                } catch (error) {
                } finally {
                    this.resultKey++
                    this.isSubmitting = false
                }
            }
        },
        // 提交减保
        async onSubmitSubtract () {
            this.submitCheck()
            this.isConfirm = async () => {
                if (this.isSubmitting) {
                    this.resultKey++
                    return
                }
                this.isSubmitting = true
                try {
                    await InsuranceSubtract({
                        policyId: this.$route.params.id as unknown as number,
                        startDate: this.startDate,
                        persons: this.tableData
                    })
                    this.$message.success('提交减保成功，正在为您返回上一页')
                    this.$router.go(-1)
                } catch (error) {
                } finally {
                    this.resultKey++
                    this.isSubmitting = false
                }
            }
        }
    },
    computed: {}
})
