
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
import { getAppName } from '@/api/base'
export default defineComponent({
    name: 'InsuranceAddSubtractAdd',
    components: {
        'alert-box': alertBox
    },
    props: ['name', 'type', 'count', 'date', 'money', 'addCount', 'isMonth'],
    data () {
        return {
            disabled: false,
            active: false,
            appName: getAppName()
        }
    },
    created () {
        this.disabled = false
    },
    methods: {
        // 取消
        cancel () {
            this.$emit('cancel')
        },
        submit () {
            if (!this.active && getAppName() === '安心优选') {
                this.$message.error('请先阅读并同意规则')
                return
            }
            this.disabled = true
            this.$emit('submit')
        }
    }
})
